import { ChevronRightIcon, CopyIcon, LinkIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  ChakraProvider,
  Code,
  Divider,
  extendTheme,
  Heading,
  HStack,
  Icon,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
  useToast,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import * as React from "react";
import { IconType } from "react-icons";
import { AiOutlineFilePdf, AiOutlineMail, AiOutlineProject } from "react-icons/ai";
import { BsPersonBadge } from "react-icons/bs";
import { GiSkills } from "react-icons/gi";
import { MdAccessTime, MdOutlineWorkHistory } from "react-icons/md";
import { SiGit } from "react-icons/si";

// Import the functions you need from the SDKs you need
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDxHM5nYTwPyiLedL2QZw5SSONVP6vmEYo",
  authDomain: "juwar-io.firebaseapp.com",
  projectId: "juwar-io",
  storageBucket: "juwar-io.appspot.com",
  messagingSenderId: "332532513041",
  appId: "1:332532513041:web:0a2dee6b2f9cfc18bf34b0",
  measurementId: "G-N13E7NS4G6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function onLinkClick(source: string, href: string) {
  logEvent(analytics, "link_click", {
    source: source,
    href: href,
  });
}

// https://chakra-ui.com/docs/styled-system/customize-theme#customizing-component-styles
const theme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        fontWeight: 600,
        color: "#cb3837",
        _hover: {
          color: "black",
          textDecoration: "none",
        },
      },
    },
    Text: {
      variants: {
        "side-label": {
          flex: 1,
          color: "#757575",
          fontFamily: "'Source Sans Pro', 'Lucida Grande', sans-serif",
          fontSize: "1rem",
          fontWeight: 700,
          lineHeight: 1.15,
        },
        "side-value": {
          color: "rgba(0,0,0,.8)",
          fontWeight: 600,
          fontSize: "1.25rem",
        },
      },
    },
  },
});

enum Tab {
  AboutMe,
  Projects,
  WorkHistory,
  Skills,
}

function calculateTotalYearsExperience(sinceDate: Date) {
  var ageDifMs = Date.now() - (sinceDate as any);
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function AboutH2({ children }: React.PropsWithChildren<{}>) {
  return (
    <Heading
      size={"md"}
      as={"h2"}
      borderBottom={"1px solid rgba(0, 0, 0, 0.1)"}
      width={"full"}
      paddingBottom={2}
      marginBottom={4}
    >
      {children}
    </Heading>
  );
}

function AboutH3({ children }: React.PropsWithChildren<{}>) {
  return (
    <Heading
      size={"sm"}
      as={"h3"}
      borderBottom={"1px solid rgba(0, 0, 0, 0.1)"}
      width={"full"}
      paddingTop={2}
      paddingBottom={2}
      marginBottom={4}
    >
      {children}
    </Heading>
  );
}

interface RightPanelLinkProps {
  href: string;
}

function RightPanelLink({ href }: RightPanelLinkProps) {
  return (
    <Link
      target={"_blank"}
      onClick={function click() {
        onLinkClick("right_panel", href);
      }}
      href={href}
      color={"rgba(0,0,0,.8)"}
    >
      {href}
    </Link>
  );
}

interface LabeledSideValueProps {
  label: string;
  value: React.ReactNode;
  labelIcon?: React.ReactNode;
  icon?: React.ReactNode;
}

function LabeledSideValue({ label, value, icon, labelIcon }: LabeledSideValueProps) {
  return (
    <VStack width={"full"} paddingTop={4} flex={1}>
      <HStack width={"full"}>
        {labelIcon}
        <Text width={"full"} variant={"side-label"}>
          {label}
        </Text>
      </HStack>
      <HStack width={"full"}>
        {icon}
        <Text variant={"side-value"}>{value}</Text>
      </HStack>
    </VStack>
  );
}

interface ReferralInfo {
  quote: string;
  company: string;
  relationship: string;
}

const ReferralMap: Record<string, ReferralInfo> = {
  shanny: {
    quote: "I worked with Justin at CS Disco, blah blah blah",
    company: "CS Disco",
    relationship: "PM of Team",
  },
  david: {
    quote: "I worked with Justin at Microsoft, blah blah blah",
    company: "Microsoft",
    relationship: "Peer and Mentor",
  },
  daniel: {
    quote: "I worked with Justin at CS Disco, blah blah blah",
    company: "CS Disco",
    relationship: "Team Lead",
  },
  leo: {
    quote: "I worked with Justin at CS Disco, blah blah blah",
    company: "CS Disco",
    relationship: "Direct Report",
  },
};

function ReferalBlock() {
  const [referralId, setReferralId] = React.useState<string | undefined>();
  const referral = referralId ? ReferralMap[referralId] : undefined;
  React.useEffect(
    function viewReferral() {
      if (referralId) {
        logEvent(analytics, "view_referral", { referralId: referralId });
      }
    },
    [referralId]
  );
  return (
    <>
      <HStack>
        <Box
          width={45}
          height={45}
          background={"red.400"}
          onClick={function openModal() {
            setReferralId("shanny");
          }}
        />
        <Box
          width={45}
          height={45}
          background={"green.400"}
          onClick={function openModal() {
            setReferralId("david");
          }}
        />
        <Box
          width={45}
          height={45}
          background={"blue.400"}
          onClick={function openModal() {
            setReferralId("daniel");
          }}
        />
        <Box
          width={45}
          height={45}
          background={"orange.400"}
          onClick={function openModal() {
            setReferralId("leo");
          }}
        />
      </HStack>
      <Modal
        isOpen={referral !== undefined}
        onClose={function closeModal() {
          setReferralId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>{referralId}</ModalHeader> */}
          <ModalHeader>🚧 Coming Soon 🚧</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Text>{referral?.quote}</Text>
            <Text>{referral?.company}</Text>
            <Text>{referral?.relationship}</Text> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

interface DoubleSideValueProps {
  labels: [string, string];
  values: [string, string];
}

function DoubleSideValue({ labels, values }: DoubleSideValueProps) {
  return (
    <HStack width={"full"}>
      <LabeledSideValue label={labels[0]} value={values[0]} />
      <LabeledSideValue label={labels[1]} value={values[1]} />
    </HStack>
  );
}

function StaticRightPanel() {
  const [hovered, setHovered] = React.useState(false);
  const toast = useToast();
  return (
    <VStack paddingX={4} paddingTop={4}>
      <Text width={"full"} color={"#757575"}>
        Email
      </Text>
      <HStack
        border={"1px #cccccc solid"}
        paddingY={"10px"}
        paddingRight={"10px"}
        paddingLeft={"10px"}
        borderRadius={"5px"}
        width={"full"}
        justifyContent={"space-between"}
      >
        <HStack>
          <ChevronRightIcon />
          <Text background={hovered ? "blue.500" : "transparent"}>justin@juwar.io</Text>
        </HStack>
        <CopyIcon
          _hover={{
            cursor: "pointer",
          }}
          onClick={() => {
            logEvent(analytics, "copy_email");
            navigator.permissions.query({ name: "clipboard-write" as any }).then((result) => {
              if (result.state === "granted" || result.state === "prompt") {
                navigator.clipboard.writeText("justin@juwar.io").then(() => {
                  toast({
                    title: "Copied email",
                    status: "success",
                  });
                });
              }
            });
          }}
          onMouseEnter={() => {
            setHovered(true);
          }}
          onMouseLeave={() => {
            setHovered(false);
          }}
        />
      </HStack>
      <LabeledSideValue
        label={"Github"}
        icon={<Icon as={SiGit} />}
        value={<RightPanelLink href={"https://github.com/justinmwarner"} />}
      />
      <Divider />
      <LabeledSideValue
        label={"LinkedIn"}
        icon={<LinkIcon />}
        value={<RightPanelLink href={"https://www.linkedin.com/in/justinmwarner"} />}
      />
      <Divider />
      <Box
        width={"full"}
        borderBottom={"2px solid rgba(137, 86, 255, .2)"}
        height={20}
        alignItems={"flex-end"}
      >
        <HStack height={"full"}>
          <LabeledSideValue
            label={"Years of Experience"}
            labelIcon={<Icon as={MdAccessTime} />}
            value={`${calculateTotalYearsExperience(new Date(2013, 5, 1))} total`}
          />
          <VStack height={"full"} paddingRight={2}>
            <Box flex={1} />
            <svg
              style={{ height: 35, paddingRight: 16 }}
              width="200"
              height="35"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="0" y1="35" x2="200" y2="0" stroke="rgb(137, 86, 255)" stroke-width="3" />
              <polygon points="0,35 200,0 200,35" fill="rgba(137, 86, 255, 0.2)" />
            </svg>
          </VStack>
        </HStack>
      </Box>
      <DoubleSideValue labels={["React", "Typescript"]} values={["8 years", "8 years"]} />
      <Divider />
      <DoubleSideValue
        labels={["Web Development", "Management"]}
        values={["10 years", "2 years"]}
      />
      <Divider />
      <DoubleSideValue labels={["UX", ""]} values={["8 years", ""]} />
      <Divider />
      <LabeledSideValue label={"Referals"} value={<ReferalBlock />} />
      <VStack gap={4} paddingTop={8} width={"full"}>
        <Button
          width={"full"}
          leftIcon={<Icon as={AiOutlineFilePdf} boxSize={6} />}
          colorScheme={"teal"}
          variant={"outline"}
          background={
            "linear-gradient(-180deg, rgba(255, 255, 255, 0.13) 0%, rgba(0, 184, 15, 0.1) 100%)"
          }
          onClick={function onEmail() {
            logEvent(analytics, "download_resume");
            (window as Window).open("./justin-resume-2023.pdf", "_blank");
          }}
        >
          Download Resume
        </Button>
        <Button
          width={"full"}
          leftIcon={<Icon as={AiOutlineMail} boxSize={6} />}
          colorScheme={"purple"}
          variant={"outline"}
          background={
            "linear-gradient(-180deg, rgba(255, 255, 255, 0.13) 0%, rgba(107, 70, 193, 0.1) 100%)"
          }
          onClick={function onEmail() {
            logEvent(analytics, "start_email");
            (window as Window).open("mailto:justin@juwar.io", "_blank");
          }}
        >
          Email Me
        </Button>
      </VStack>
    </VStack>
  );
}

function AboutMeContent() {
  return (
    <VStack>
      <Heading
        position={"relative"}
        as={"h1"}
        size={"lg"}
        width={"full"}
        borderBottom={"1px solid grey"}
        marginBottom={4}
      >
        <Code fontSize={"xxl"} marginBottom={2}>
          Justin Warner
        </Code>
      </Heading>
      <Text width={"full"} paddingBottom={4}>
        As a passionate frontend developer, I thrive on the creative and technical challenges that
        come with crafting engaging websites and mobile apps. My expertise lies in harnessing the
        power of React, JavaScript, and TypeScript to bring digital experiences to life.
      </Text>
      <Text width={"full"} paddingBottom={4}>
        With a versatile background, I've had the privilege of working in a range of industries,
        from data visualization and big data solutions to portfolio management, e-discovery legal
        tech, and even my personal passion, cooking. This diverse experience has honed my
        problem-solving skills and creativity, enabling me to approach projects with a unique
        perspective.
      </Text>
      <Text width={"full"} paddingBottom={4}>
        I'm committed to delivering high-quality solutions that not only meet but exceed user
        expectations. With a deep understanding of both code and design, I'm always ready to embark
        on new projects and challenges in the ever-evolving world of frontend development.
      </Text>
      <AboutH2>Roles of Interest</AboutH2>
      <Text width={"full"}>
        I'm interested in several types of roles, if you match one of these, I'd expect we're a good
        fit.
      </Text>
      <UnorderedList>
        <ListItem>
          <Text as={"b"}>Managerial Role for Fullstack or Frontend Team:</Text>
          My tenure at CS Disco, where I excelled in a similar role, has equipped me with the
          leadership acumen to drive teams towards excellence. Moreover, I have dedicated 9me to
          teaching computer science at numerous high schools through the TEALS program, underscoring
          my passion for mentorship and educa9on.
        </ListItem>
        <ListItem>
          <Text as={"b"}>Micro-Frontends and Platform Development:</Text>I am interested in
          micro-frontends and the underlying platform. Additionally, I am excited about contributing
          to or managing a shared component library, whether used internally or as an open-source
          effort.
        </ListItem>
        <ListItem>
          <Text as={"b"}>Challenging and Collaborative Opportunities:</Text>I thrive in environments
          that tackle complex problems collaborativly. My strengths lie in in-depth feature planning
          and system design, culminating in the delivery of meticulously crafted, thoroughly tested,
          resilient software. I am at my best when entrusted with the 9me and resources to ensure
          excellence.
        </ListItem>
      </UnorderedList>
      <AboutH3>Keywords</AboutH3>
      <HStack spacing={2} width={"full"} flexWrap={"wrap"}>
        {Object.values(Technology).map(function renderKeyword(technology) {
          return (
            <Link
              target={"_blank"}
              onClick={function click() {
                onLinkClick("about_me", TechnologyLinkMap[technology]);
              }}
              href={TechnologyLinkMap[technology]}
            >
              {technology}
            </Link>
          );
        })}
      </HStack>
    </VStack>
  );
}

interface Job {
  id: string;
  company: string;
  title: string;
  dates: [Date, Date];
  links: string[];
  details: string[];
  technologies: Technology[];
}

enum Technology {
  React = "ReactJS",
  TypeScript = "TypeScript",
  JavaScript = "JavaScript",
  ReactNative = "react-native",
  GraphQL = "GraphQL",
  Apollo = "Apollo",
  Zustand = "Zustand",
  GithubActions = "Github Actions CI",
  Figma = "Figma",
  Playwright = "Playwright",
  Cypress = "Cypress",
  MicroFrontends = "micro-frontends",
  Mentorship = "Mentorship",
  CareerGrowth = "Career Growth",
  OneOnOne = "one-on-one",
  JSS = "jss",
  Redux = "redux",
  Storybook = "Storybook",
  ReactTestingLibrary = "React Testing Library",
  InVision = "InVision",
  Jira = "Jira",
  ResponsiveDevelopmentAndDesign = "responsive development and design",
  Jest = "Jest",
  Webpack = "Webpack",
  CSS = "CSS",
  Node = "Node",
  Npm = "npm",
  Git = "Git",
  StyledComponents = "Styled Components",
  SCSS = "SCSS",
  SASS = "SASS",
  Fiddler = "Fiddler",
  RESTAPI = "REST API",
  PowerShell = "PowerShell",
  Bash = "Bash",
  Python = "Python",
  Azure = "Azure",
  AWS = "AWS",
  Docker = "Docker",
  ASPNET = "ASP.NET",
  PerformanceMonitoring = "Performance Monitoring",
  CSharp = "C#",
  Flux = "Flux",
  VSTS = "VSTS",
  Sinon = "Sinon",
  Karma = "Karma",
  Gulp = "Gulp",
  Grafana = "Grafana",
  HTML = "HTML",
}

const TechnologyLinkMap: Record<Technology, string> = {
  [Technology.ReactNative]: "https://npmjs.com/package/react-native",
  [Technology.TypeScript]: "https://npmjs.com/package/typescript",
  [Technology.GraphQL]: "https://www.wikipedia.org/wiki/GraphQL",
  [Technology.Apollo]: "https://www.wikipedia.org/wiki/Apollo_Client",
  [Technology.Zustand]: "https://www.npmjs.com/package/zustand",
  [Technology.GithubActions]: "https://github.com/features/actions",
  [Technology.Figma]: "https://www.figma.com/files/recents-and-sharing?fuid=1228059436867897692",
  [Technology.Playwright]: "https://www.npmjs.com/package/playwright",
  [Technology.React]: "https://npmjs.com/package/react",
  [Technology.Cypress]: "https://npmjs.com/package/cypress",
  [Technology.MicroFrontends]: "https://en.wikipedia.org/wiki/Microfrontend",
  [Technology.JavaScript]: "https://npmjs.com/package/javascript",
  [Technology.Mentorship]: "https://en.wikipedia.org/wiki/Mentorship",
  [Technology.CareerGrowth]: "https://en.wikipedia.org/wiki/Career_development",
  [Technology.OneOnOne]: "https://en.wikipedia.org/wiki/Team_management",
  [Technology.JSS]: "https://www.npmjs.com/package/jss",
  [Technology.Redux]: "https://npmjs.com/package/redux",
  [Technology.Storybook]: "https://npmjs.com/package/storybook",
  [Technology.ReactTestingLibrary]: "https://npmjs.com/package/@testing-library/react",
  [Technology.InVision]: "https://www.invisionapp.com/",
  [Technology.Jira]: "https://www.wikipedia.org/wiki/Jira_(software)",
  [Technology.ResponsiveDevelopmentAndDesign]:
    "https://en.wikipedia.org/wiki/Responsive_web_design",
  [Technology.Jest]: "https://npmjs.com/package/jest",
  [Technology.Webpack]: "https://npmjs.com/package/webpack",
  [Technology.CSS]: "https://www.w3schools.com/css/",
  [Technology.Node]: "https://nodejs.org/",
  [Technology.Npm]: "https://www.npmjs.com/",
  [Technology.Git]: "https://git-scm.com/",
  [Technology.StyledComponents]: "https://npmjs.com/package/styled-components",
  [Technology.SCSS]: "https://www.sass-lang.com/",
  [Technology.SASS]: "https://www.sass-lang.com/",
  [Technology.Fiddler]: "https://www.telerik.com/fiddler",
  [Technology.RESTAPI]: "https://www.restapitutorial.com/",
  [Technology.PowerShell]: "https://docs.microsoft.com/en-us/powershell/",
  [Technology.Bash]: "https://www.gnu.org/software/bash/",
  [Technology.Python]: "https://www.python.org/",
  [Technology.Azure]: "https://azure.microsoft.com/",
  [Technology.AWS]: "https://aws.amazon.com/",
  [Technology.Docker]: "https://www.docker.com/",
  [Technology.ASPNET]: "https://dotnet.microsoft.com/apps/aspnet",
  [Technology.PerformanceMonitoring]:
    "https://en.wikipedia.org/wiki/Profiling_(computer_programming)",
  [Technology.CSharp]: "https://docs.microsoft.com/en-us/dotnet/csharp/",
  [Technology.Flux]: "https://facebook.github.io/flux/",
  [Technology.VSTS]: "https://visualstudio.microsoft.com/team-services/",
  [Technology.Sinon]: "https://sinonjs.org/",
  [Technology.Karma]: "https://karma-runner.github.io/",
  [Technology.Gulp]: "https://gulpjs.com/",
  [Technology.Grafana]: "https://grafana.com/",
  [Technology.HTML]: "https://en.wikipedia.org/wiki/HTML/",
};

const Jobs: Job[] = [
  {
    id: "poodlepop",
    company: "PoodlePop LLC",
    title: "The Sous Chef",
    dates: [new Date(2022, 9, 1), new Date(Date.now())],
    links: ["https://thesouschefapp.com"],
    details: [
      "Founded and launched a successful mobile app startup, garnering over 10,000 downloads",
    ],
    technologies: [
      Technology.Apollo,
      Technology.Figma,
      Technology.GithubActions,
      Technology.GraphQL,
      Technology.Playwright,
      Technology.ReactNative,
      Technology.TypeScript,
      Technology.Zustand,
    ],
  },
  {
    id: "cs-disco-manager",
    company: "CS Disco",
    title: "Engineering Lead",
    dates: [new Date(2021, 7, 1), new Date(2022, 9, 1)],
    links: ["https://csdisco.com/offerings/ediscovery"],
    details: [
      "Chosen as one of two managers from team of 12 to lead 2 frontend and 2 backend engineers during the restructuring process of our larger organization, ensured that individual roadmaps integrated seamlessly with larger corporate strategy, optimized productivity and team satisfaction while maintaining focus on key business objectives.",
      "Drove team development and performance, conducting regular 1:1 meetings with team members, nurturing a culture of continuous learning and improvement, gathering and delivering constructive feedback from peers within the greater team, resulting in increased individual and team effectiveness.",
    ],
    technologies: [Technology.OneOnOne, Technology.CareerGrowth, Technology.Mentorship],
  },
  {
    id: "cs-disco-ic",
    company: "CS Disco",
    title: "Senior UI Architect",
    dates: [new Date(2020, 7, 1), new Date(2021, 7, 1)],
    links: ["https://csdisco.com/offerings/ediscovery/cecilia"],
    details: [
      "Architected UI componentry and state management for 10+ features ",
      "Led the successful delivery of Topic Clustering, a crucial feature for legal document analysis. After joining midway due to missed deadlines, I re-structured, re-prioritized, and re-estimated work efforts amongst team members as well as took over 50% of development to get the project back on track and completed on time. This feature became a fundamental component of AI chatbot Cecilia.",
      "Led development efforts to enhance a search visualization page which resulted in a reduction of the number of clicks users needed to accomplish search visualization analysis by over 50%.",
      "Collaborated closely with the UX team and leveraged Figma during the planning and design phases of projects to provide essential feedback and ensure feasibility and accessibility resulting in a user-friendly and inclusive design.",
    ],
    technologies: [
      Technology.React,
      Technology.TypeScript,
      Technology.JavaScript,
      Technology.GraphQL,
      Technology.Cypress,
      Technology.Figma,
      Technology.MicroFrontends,
      Technology.Jira,
    ],
  },
  {
    id: "salesforce",
    company: "Salesforce",
    title: "Senior User Interface Engineer",
    dates: [new Date(2019, 8, 1), new Date(2020, 7, 1)],
    links: ["https://www.salesforce.com/blog/what-is-philanthropy-cloud"],
    details: [
      "Spearheaded a transformative TypeScript migration project addressing a long-standing need within the frontend team of over 15 engineers, successfully converting more than 3000 files to TypeScript, enhancing code quality and providing substantial development efficiencies. Additionally, provided documentation and migration guides for common cases within the codebase so each team could slowly migrate without disrupting business deadlines.",
      "Consistently delivered features on time to enhance the philanthropy content management system.",
      "Led efforts on adoption of React Testing Library across multiple front-end teams resulting in testing efficiencies across teams and an improvement in testing coverage. ",
    ],
    technologies: [
      Technology.JavaScript,
      Technology.TypeScript,
      Technology.JSS,
      Technology.Redux,
      Technology.GraphQL,
      Technology.Apollo,
      Technology.React,
      Technology.Storybook,
      Technology.ReactTestingLibrary,
      Technology.InVision,
      Technology.Jira,
    ],
  },
  {
    id: "microsoft-wpa",
    company: "Microsoft",
    title: "Software Engineer II",
    dates: [new Date(2017, 11, 1), new Date(2019, 8, 1)],
    links: ["https://www.microsoft.com/en-us/microsoft-viva"],
    details: [
      "Chosen, out of the five frontend engineers, to lead a V-Team to integrate a 10+ person IDC (India Development Center) team within 3 months, transforming coding practices and ensuring a seamless transition. I successfully guided the team, focusing on code quality, structure, and comprehensibility. Over the period, the team transitioned to self-sufficiency, producing high-quality work and well-structured code.",
      "Significantly improved productivity for the engineering team on a mature product by implementing various enhancements: added CSS modules reducing dead CSS code by over 20%, added bundle links and code coverage per PR for four teams, and introduced templates for design and architecture.",
      "Led a greenfield project around On-Call rotation to develop a tool to decrease the time it took for debugging high-priority (severity 0-2) issues. Commonly done tasks like feature flighting time were reduced from hours to minutes and completed with reduced risk. This project included the leading and mentoring of a contractor.",
    ],
    technologies: [
      Technology.React,
      Technology.Redux,
      Technology.TypeScript,
      Technology.Webpack,
      Technology.SASS,
      Technology.Jest,
      Technology.InVision,
      Technology.Figma,
      Technology.Jira,
    ],
  },
  {
    id: "microsoft-project",
    company: "Microsoft",
    title: "Software Engineer",
    dates: [new Date(2014, 9, 1), new Date(2017, 11, 1)],
    links: ["https://www.microsoft.com/en-us/microsoft-365/project/project-portfolio-management"],
    details: [
      "Played a pivotal role in the development of a greenfield project called Portfolio Experience. Collaborated with a cross-functional team to deliver UX features based on InVision designs, which resulted in a user-friendly and inclusive product leveraged by over 1000 companies.",
    ],
    technologies: [
      Technology.React,
      Technology.Flux,
      Technology.TypeScript,
      Technology.Gulp,
      Technology.Webpack,
      Technology.SASS,
      Technology.Sinon,
      Technology.Karma,
      Technology.VSTS,
      Technology.InVision,
    ],
  },
  {
    id: "microsoft-intern",
    company: "Microsoft",
    title: "SDET Intern",
    dates: [new Date(2013, 5, 1), new Date(2013, 7, 1)],
    links: ["https://tasks.office.com"],
    details: [
      "Developed testing automation to the then called Tasks (currently Planner) Windows 8 app.",
      "Researched and developed performance tracking and analytics using internal C# libraries within Microsoft.",
    ],
    technologies: [Technology.CSharp, Technology.PerformanceMonitoring, Technology.ASPNET],
  },
];

function JobDivider() {
  return <Box flex={1} borderBottom={"1px dotted rgba(0, 0, 0, .2)"} height={"1px"} marginX={2} />;
}

function JobText({ children }: React.PropsWithChildren<{}>) {
  return (
    <Text noOfLines={1} flex={0} flexBasis={"auto"}>
      {children}
    </Text>
  );
}

interface AccordionHeaderProps {
  end?: boolean;
}

function AccordionHeader({ end, children }: React.PropsWithChildren<AccordionHeaderProps>) {
  return (
    <Text
      display={"flex"}
      flex={1}
      noOfLines={1}
      fontWeight={"600"}
      textAlign={end ? "end" : undefined}
    >
      {children}
    </Text>
  );
}

function WorkHistoryContent() {
  return (
    <VStack width={"full"}>
      <HStack
        height={9}
        borderWidth={0}
        marginBottom={3}
        width={"full"}
        paddingX={4}
        justifyContent={"space-between"}
      >
        <AccordionHeader>Title</AccordionHeader>
        <Box width={"130px"}>
          <AccordionHeader>Company</AccordionHeader>
        </Box>
        <Box width={"190px"} alignItems={"flex-end"} paddingRight={2}>
          <AccordionHeader end>Dates</AccordionHeader>
        </Box>
      </HStack>
      <Accordion
        defaultIndex={[0]}
        width={"full"}
        borderWidth={0}
        borderColor={"transparent"}
        allowMultiple
      >
        {Jobs.map(function renderJob(job) {
          const [startDate, endDate] = job.dates;
          const startMonth = startDate.getMonth();
          const startYear = startDate.getFullYear();
          const endMonth = endDate.getMonth();
          const endYear = endDate.getFullYear();
          return (
            <AccordionItem minHeight={9} borderWidth={0} marginBottom={3}>
              {({ isExpanded }) => {
                return (
                  <>
                    <Text as={"h2"} height={"full"}>
                      <AccordionButton
                        height={"full"}
                        onClick={function onAccordionClick() {
                          logEvent(analytics, "accordion_open", {
                            job: job.id,
                            expanding: !isExpanded,
                          });
                        }}
                      >
                        <AccordionIcon />
                        <JobText>{job.title}</JobText>
                        <JobDivider />
                        <JobText>{job.company}</JobText>
                        <Box width={"130px"}>
                          <JobDivider />
                        </Box>
                        <Box width={"130px"}>
                          <JobText>{`${startMonth}/${startYear} - ${endMonth}/${endYear}`}</JobText>
                        </Box>
                      </AccordionButton>
                    </Text>
                    <AccordionPanel paddingBottom={4} paddingX={8}>
                      <UnorderedList>
                        {job.details.map(function renderJobDetail(detail) {
                          return <ListItem>{detail}</ListItem>;
                        })}
                      </UnorderedList>
                      <SkillSection title="Technologies/Skills" technologies={job.technologies} />

                      {job.links.length > 0 && (
                        <Box paddingTop={2}>
                          <SimpleHeader>Links: </SimpleHeader>
                          <UnorderedList>
                            {job.links.map(function renderJobLink(link) {
                              return (
                                <ListItem>
                                  <Link
                                    target={"_blank"}
                                    onClick={function click() {
                                      onLinkClick("work_history", link);
                                    }}
                                    key={link}
                                    href={link}
                                  >
                                    {link}
                                  </Link>
                                </ListItem>
                              );
                            })}
                          </UnorderedList>
                        </Box>
                      )}
                    </AccordionPanel>
                  </>
                );
              }}
            </AccordionItem>
          );
        })}
      </Accordion>
    </VStack>
  );
}

const SoftSkills: Technology[] = [Technology.ResponsiveDevelopmentAndDesign];
const AdvancedHardSkills: Technology[] = [
  Technology.TypeScript,
  Technology.React,
  Technology.Redux,
  Technology.GraphQL,
  Technology.ReactNative,
  Technology.Jest,
  Technology.Webpack,
  Technology.JavaScript,
  Technology.CSS,
  Technology.Node,
  Technology.Apollo,
  Technology.Npm,
  Technology.Git,
  Technology.StyledComponents,
  Technology.Storybook,
  Technology.Figma,
  Technology.InVision,
];

const KnowledgeableHardSkills: Technology[] = [
  Technology.SCSS,
  Technology.Cypress,
  Technology.Fiddler,
  Technology.RESTAPI,
  Technology.PowerShell,
  Technology.Bash,
  Technology.Python,
  Technology.Azure,
  Technology.AWS,
  Technology.Docker,
];

function SimpleHeader({ children }: React.PropsWithChildren<{}>) {
  return (
    <Text width={"full"} fontWeight={600}>
      {children}
    </Text>
  );
}

interface SkillSectionProps {
  title: string;
  technologies: Technology[];
}

function SkillSection({ title, technologies }: SkillSectionProps) {
  return (
    <VStack width={"full"} paddingTop={4}>
      <SimpleHeader>{`${title} (${technologies.length})`}</SimpleHeader>
      <Wrap width={"full"}>
        {technologies.map(function renderSkill(technology) {
          return (
            <Link
              target={"_blank"}
              onClick={function click() {
                onLinkClick("skills", TechnologyLinkMap[technology]);
              }}
              key={TechnologyLinkMap[technology]}
              href={TechnologyLinkMap[technology]}
            >
              {technology}
            </Link>
          );
        })}
      </Wrap>
    </VStack>
  );
}

function SkillsContent() {
  return (
    <VStack>
      <SkillSection title={"Hard skills"} technologies={AdvancedHardSkills} />
      <SkillSection title={"Knowledgeable skills"} technologies={KnowledgeableHardSkills} />
      <SkillSection title={"Soft skills"} technologies={SoftSkills} />
    </VStack>
  );
}

type Project = {
  id: string;
  name: string;
  description: string;
  link: string;
  details: string[];
  technologies: Technology[];
};

const Projects: Project[] = [
  {
    id: "the-sous-chef",
    name: "The Sous Chef",
    description: "Mobile app",
    link: "https://thesouschefapp.com",
    details: [
      "React Native app for both iOS and Android for cooks",
      "Uses information about pairings between ingredients to help users create recipes",
    ],
    technologies: [
      Technology.Apollo,
      Technology.Figma,
      Technology.GithubActions,
      Technology.GraphQL,
      Technology.Playwright,
      Technology.ReactNative,
      Technology.TypeScript,
      Technology.Zustand,
    ],
  },
  {
    id: "teals",
    name: "TEALS",
    description: "Teaching CS to high school students",
    link: "https://www.microsoft.com/en-us/teals",
    details: [
      "Taught introductory computer science and programming",
      "Developed curriculumn and projects based on interests within class.",
      "Programming languages used are Scratch/Snap and Python",
    ],
    technologies: [Technology.Mentorship],
  },
  {
    id: "fluent-ui",
    name: "Fluent UI",
    description: "Microsoft Component Library",
    link: "https://github.com/microsoft/fluentui",
    details: ["Contributed to Microsoft Fabric (now named Fluent UI) internally to Microsoft"],
    technologies: [Technology.InVision, Technology.Figma, Technology.TypeScript, Technology.React],
  },
  {
    id: "mendel",
    name: "Project Mendel",
    description: "The smarts behind growing",
    link: "https://news.microsoft.com/features/how-does-your-garden-grow-microsoft-finds-success-sustainability-in-urban-farming-experiment/",
    details: [
      "Worked on UI / charting technologies to help track distributed growing towers across Microsoft campus",
      "Winner of Industries category",
    ],
    technologies: [Technology.Grafana, Technology.HTML, Technology.CSS, Technology.JavaScript],
  },
];

function ProjectsContent() {
  return (
    <VStack width={"full"}>
      <HStack
        height={9}
        borderWidth={0}
        marginBottom={3}
        width={"full"}
        paddingX={4}
        justifyContent={"space-between"}
      >
        <AccordionHeader>Project</AccordionHeader>
        <AccordionHeader end>Description</AccordionHeader>
      </HStack>
      <Accordion
        defaultIndex={[0]}
        width={"full"}
        borderWidth={0}
        borderColor={"transparent"}
        allowMultiple
      >
        {Projects.map(function renderProject(project) {
          return (
            <AccordionItem minHeight={9} borderWidth={0} marginBottom={3}>
              {({ isExpanded }) => {
                return (
                  <>
                    <Text as={"h2"} height={"full"}>
                      <AccordionButton
                        height={"full"}
                        onClick={function onAccordionClick() {
                          logEvent(analytics, "accordion_open", {
                            project: project.id,
                            expanding: `${!isExpanded}`,
                          });
                        }}
                      >
                        <AccordionIcon />
                        <JobText>{project.name}</JobText>
                        <JobDivider />
                        <JobText>{project.description}</JobText>
                      </AccordionButton>
                    </Text>
                    <AccordionPanel paddingBottom={4} paddingX={8}>
                      <UnorderedList>
                        {project.details.map(function renderProjectDetail(detail) {
                          return <ListItem>{detail}</ListItem>;
                        })}
                      </UnorderedList>
                      <SkillSection
                        title="Technologies/Skills"
                        technologies={project.technologies}
                      />
                      <Box paddingTop={2}>
                        <SimpleHeader>Links: </SimpleHeader>
                        <UnorderedList>
                          <ListItem>
                            <Link
                              target={"_blank"}
                              onClick={function click() {
                                onLinkClick("project", project.link);
                              }}
                              href={project.link}
                            >
                              {project.link}
                            </Link>
                          </ListItem>
                        </UnorderedList>
                      </Box>
                    </AccordionPanel>
                  </>
                );
              }}
            </AccordionItem>
          );
        })}
      </Accordion>
    </VStack>
  );
}

function isNumber(value: any): value is number {
  return typeof value === "number";
}

interface TabBarProps {
  selectedTab: Tab;
  onTabChange: (tab: Tab) => void;
}

function TabBar({ selectedTab, onTabChange }: TabBarProps) {
  return (
    <Stack width={"100%"} spacing={0} flexDirection={{ base: "column", md: "row" }}>
      {Object.values(Tab)
        .filter(function filterTabEnum(t) {
          return isNumber(t);
        })
        .map(function renderTab(t) {
          const tab = t as Tab;
          const { text, background, color, icon } = TabToPropertiesMap[tab];
          const isSelected = tab === selectedTab;
          return (
            <Center
              flex={1}
              borderColor={background}
              borderBottomWidth={{ base: 0, md: 2 }}
              borderLeftWidth={{ base: 3, md: 0 }}
              background={isSelected ? background + "33" : "transparent"}
              _hover={{
                background: isSelected ? undefined : background + "33",
              }}
            >
              <Button
                leftIcon={<Icon boxSize={6} as={icon} />}
                variant={"ghost"}
                colorScheme={color}
                justifyContent={"flex-start"}
                borderBottomRadius={0}
                color={color}
                fontWeight={600}
                onClick={function onTabClick() {
                  logEvent(analytics, "tab_change", { tab: tab });
                  onTabChange(tab);
                }}
              >
                {text}
              </Button>
            </Center>
          );
        })}
    </Stack>
  );
}

interface TabProperties {
  text: string;
  background: string;
  color: string;
  icon: IconType;
}

const TabBarBackgroundsInOrder = ["#ffcd3a", "#bc3433", "#c836c3", "#8956ff", "#29abe2"];

const TabBarColrosInOrder = ["#886701", "#bc3433", "#782175", "#29008a", "#146a90"];

const Sections: Record<Tab, Pick<TabProperties, "text" | "icon">> = {
  [Tab.AboutMe]: {
    text: "About Me",
    icon: BsPersonBadge,
  },
  [Tab.WorkHistory]: {
    text: "Work History",
    icon: MdOutlineWorkHistory,
  },
  [Tab.Projects]: {
    text: "Personal Projects",
    icon: AiOutlineProject,
  },
  [Tab.Skills]: {
    text: "Skills",
    icon: GiSkills,
  },
};

const TabToPropertiesMap: Record<Tab, TabProperties> = Object.entries(Sections).reduce(
  function configureSection(result, [tabKey, section], index) {
    result[tabKey as unknown as Tab] = {
      ...section,
      background: TabBarBackgroundsInOrder[index],
      color: TabBarColrosInOrder[index],
    };
    return result;
  },
  {} as Record<Tab, TabProperties>
);

interface TabContentProps {
  tab: Tab;
}

function TabContent({ tab }: TabContentProps) {
  switch (tab) {
    case Tab.AboutMe:
      return <AboutMeContent />;
    case Tab.Projects:
      return <ProjectsContent />;
    case Tab.WorkHistory:
      return <WorkHistoryContent />;
    case Tab.Skills:
      return <SkillsContent />;
  }
}

export const App = () => {
  const [tab, setTab] = React.useState(Tab.AboutMe);

  return (
    <ChakraProvider theme={theme}>
      <Box
        flex={1}
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
        paddingTop={{ base: 0, md: 160 }}
        height={"full"}
      >
        <VStack maxWidth={1200} width={"100%"} paddingX={4}>
          <VStack width={"100%"} alignItems={"flex-start"}>
            <Text fontSize={"lg"} fontWeight={"bold"}>
              Justin Warner
            </Text>
            <HStack marginBottom={2} divider={<Text paddingX={1}>•</Text>}>
              <Text fontSize={"sm"}>3.2.0</Text>
              <Text fontSize={"sm"}>Open to Work</Text>
              <Text fontSize={"sm"}>He / Him</Text>
            </HStack>
          </VStack>
          <TabBar selectedTab={tab} onTabChange={setTab} />
          <Stack
            width={"100%"}
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box flex={2} height={"100%"} paddingTop={4} paddingRight={8}>
              <TabContent tab={tab} />
            </Box>
            <Box flex={1}>
              <StaticRightPanel />
            </Box>
          </Stack>
        </VStack>
      </Box>
      <Center flex={0} paddingTop={{ base: 2, md: 10 }}>
        <Text>
          Shamelessly stolen from{" "}
          <Link
            target={"_blank"}
            onClick={function click() {
              onLinkClick("stolen", "npmjs.com");
            }}
          >
            https://www.npmjs.com/
          </Link>
        </Text>
      </Center>
    </ChakraProvider>
  );
};
